import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Timeline from "../components/timeline"

const ExperiencePage = ({data, location}) => {
  const experiences = data.allExperience.edges

  return (
    <Layout location={location}>
      <SEO title="Home"/>
      <section>
        <Container>
          <h1>Experience</h1>
          <Timeline data={experiences}/>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        allExperience {
            edges {
                node {
                    id
                    company
                    description
                    title
                }
            }
        }
    }
`

export default ExperiencePage
