import React from 'react';
import PropTypes from 'prop-types';
import styles from './experience-element.module.scss'

const Experience = props => {
  const {className, title, description, company} = props

  return (
    <div className={className}>
      <div className={styles.title}>{title.charAt(0).toUpperCase() + title.slice(1)}</div>
      <div className={styles.company}>{company}</div>
      <div className={styles.divider}/>
      <ul className={styles.description}>
        {description.map((point, index) => (
          <li key={`${title}-point-${index}`} className={styles.point}>{point}</li>
        ))}
      </ul>
    </div>
  );
};

Experience.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  company: PropTypes.string.isRequired
};

export default Experience;