import React from 'react';
import PropTypes from 'prop-types';
import styles from './timeline.module.scss'
import Experience from "./experience"
import clsx from "clsx"

const Timeline = props => {
  const {data} = props

  return (
    <div className={styles.relativeContainer}>
      <div className={styles.timeline}>
        {data.map(({node}, index) => {
          return (
            <Experience
              className={clsx(styles.timelineContainer, index % 2 === 0 ? styles.left : styles.right)}
              key={node.id}
              title={node.title}
              description={node.description}
              company={node.company}/>
          )
        })}
      </div>
    </div>
  );
};

Timeline.propTypes = {
  data: PropTypes.array
};

export default Timeline;